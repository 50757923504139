.site-head {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255, .8);

    @media (prefers-color-scheme: dark) {
        background-color: rgba(0,0,0,.8);
    }

    @media (--from-tablet) {
        padding: 20px;
    }

    @media (--until-tablet) {
        left: 50%;
        transform: translate(-50%, 0);
        padding: 10px 20px;
    }
}

.site-logo {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 120%;
    background-image: url(../img/logo--davidsteer.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    transition: .5s transform ease;
    transform: scale(1);

    &:hover,
    &:focus {
        transform: scale(.9);
    }

    @media (prefers-color-scheme: dark) {
        background-image: url(../img/logo--davidsteer-dark.png);
    }

    @media (--desktop) {
        width: 240px;
        height: 119px;
    }

    @media (--tablet) {
        width: 180px;
        height: 90px;
    }

    @media (--until-tablet) {
        width: 100px;
        height: 50px;
    }
}
