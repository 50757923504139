.slides,
.slides__item {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    @media (--from-tablet) {
        height: 100%;
    }

    @media (--tablet) {
        height: 100%;
    }

    @media (--until-tablet) {
        height: 100vh;
    }
}

.slides__item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 0;
    z-index: -1;
    transition: 1s opacity ease, 1s z-index ease;
    overflow: hidden;

    &.is_active {
        opacity: 1;
        z-index: 1;
    }
}

.slides__item--1 {
    background-image: url(../img/slides/slide--butterfly.jpg);
}

.slides__item--2 {
    background-image: url(../img/slides/slide--mouse.jpg);
}

.slides__item--3 {
    background-image: url(../img/slides/slide--grasshopper.jpg);
}

.slides__item--4 {
    background-image: url(../img/slides/slide--spider.jpg);
}

.slides__item--5 {
    background-image: url(../img/slides/slide--frost.jpg);
}

.slides__item--6 {
    background-image: url(../img/slides/slide--butterfly2.jpg);
}

.slides__item--7 {
    background-image: url(../img/slides/slide--grasshopper2.jpg);
}

.slides__item--8 {
    background-image: url(../img/slides/slide--dew.jpg);
}

.slides__item--9 {
    background-image: url(../img/slides/slide--fly.jpg);
}

.slides__item--10 {
    background-image: url(../img/slides/slide--butterfly3.jpg);
}

.slides__item--11 {
    background-image: url(../img/slides/slide--flower.jpg);
}

.slides__details {
    position: absolute;
    left: 0;
    background-color: rgba(255,255,255,.8);
    box-sizing: border-box;
    transform: translate(-100%, 0);
    transition: 1s transform ease;

    .is_active & {
        transform: translate(0,0);
    }

    @media (prefers-color-scheme: dark) {
        background-color: rgba(0,0,0,.8);
        color: #fff;
    }

    @media (--from-tablet) {
        padding: 20px;
    }

    @media (--desktop) {
        top: 169px;
        width: 280px;
    }

    @media (--tablet) {
        top: 140px;
        width: 220px;
    }

    @media (--until-tablet) {
        top: 120px;
        width: 180px;
        padding: 10px;
    }
}

.slides__title {
    margin-top: 0;
    margin-bottom: 10px;
    color: var(--safety);

    @media (--from-tablet) {
        font-size: 18px;
        line-height: 24px;
    }

    @media (--until-tablet) {
        font-size: 16px;
        line-height: 20px;
    }
}

.slides__meta {
    margin-top: 0;
    margin-bottom: 0;
}
