@import 'base/_variables';
@import 'base/_media';
@import 'base/_fonts';
@import 'utilities/_u-vh';
@import 'utilities/_u-a11y';
@import 'site/_fallbacks';
@import 'site/_site-head';
@import 'site/_site-foot';
@import 'site/_slides';

/* stylelint-disable */
body {
    margin: 0;
    font-family: var(--body);

    @media (--desktop) {
        font-size: 18px;
        line-height: 24px;
    }

    @media (--tablet) {
        font-size: 16px;
        line-height: 22px;
    }

    @media (--until-tablet) {
        font-size: 14px;
        line-height: 20px;
    }
}

main {
    height: 100vh;
}
/* stylelint-enable */
