:root {
    --a11y: #f6005f;
    --cool: #0c8fe8;
    --safety: #e66900;
    --stormdust: #636463;
    --rangoon: #1a1919;
    --azure: #0c7de3;

    --body: Titillium, system-ui, sans-serif;
}
