/* stylelint-disable */
.u-no-focus a,
.u-no-focus button,
.u-no-focus input,
.u-no-focus select,
.u-no-focus textarea,
.u-no-focus label {
    outline: 0;
}

.u-keyboard-use a:focus,
.u-keyboard-use button:focus,
.u-keyboard-use input:focus,
.u-keyboard-use select:focus,
.u-keyboard-use textarea:focus,
.u-keyboard-use label:focus {
    outline: 2px solid var(--a11y);
}
/* stylelint-enable */
