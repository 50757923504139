.noscript-warning,
.old-browser {
    background-color: var(--apple);
    color: #000;
    font-size: 18px;
    margin: 0 auto;
    text-align: center;
    line-height: calc(24 / 18 * 100%);
    padding: 40px 20px;
}

.old-browser {
    background-color: var(--claret);
    display: none;

    &.is_visible {
        display: block;
    }
}

.old-browser__title {
    font-size: 30px;
    line-height: calc(36 / 30 * 100%);
}

.old-browser__content {
    margin: 0 auto 20px;
    max-width: 600px;
    font-size: 18px;
    line-height: calc(24 / 18 * 100%);

    :last-child {
        margin-bottom: 0;
    }
}
