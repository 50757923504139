.site-foot {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    color: var(--rangoon);
    background-color: rgba(255,255,255, .8);

    @media (prefers-color-scheme: dark) {
        background-color: rgba(0,0,0,.8);
        color: #fff;
    }

    @media (--from-tablet) {
        padding: 20px;
    }

    @media (--until-tablet) {
        padding: 10px;
    }
}

.site-foot__copyrights {
    margin-top: 0;
    margin-bottom: 5px;

    @media (--from-tablet) {
        font-size: 14px;
        line-height: 20px;
    }

    @media (--until-tablet) {
        font-size: 12px;
        line-height: 18px;
    }
}

.site-foot__list {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;

    @media (--from-tablet) {
        clear: fix;
    }
}

.site-foot__item {
    @media (--from-tablet) {
        font-size: 14px;
        line-height: 20px;
        display: inline-block;

        &:not(:last-of-type) {
            margin-right: 20px;
        }
    }

    @media (--until-tablet) {
        font-size: 12px;
        line-height: 18px;

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }
}

.site-foot__link {
    color: var(--azure);
    text-decoration: none;
    transition: .25s color ease;

    &:hover,
    &:focus {
        color: var(--safety);
    }
}
