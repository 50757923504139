@font-face {
    font-family: 'Titillium';
    src:
        url(../fonts/titilliumweb-bold-webfont.woff2) format('woff2'),
        url(../fonts/titilliumweb-bold-webfont.woff) format('woff');
    font-weight: var(--fw-bold);
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium';
    src:
        url(../fonts/titilliumweb-light-webfont.woff2) format('woff2'),
        url(../fonts/titilliumweb-light-webfont.woff) format('woff');
    font-weight: var(--fw-light);
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium';
    src:
        url(../fonts/titilliumweb-regular-webfont.woff2) format('woff2'),
        url(../fonts/titilliumweb-regular-webfont.woff) format('woff');
    font-weight: var(--fw-regular);
    font-style: normal;
    font-display: swap;
}
